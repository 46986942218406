/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect } from 'react';
import cmsUtils from '../../cmsUtils';
import utils from 'utils';
import style from './disqusCommentStyle';

export function DisqusComment(props) {

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'disqusComment', item.cssClass || item.anchorName || '');
    const _disqusURL = cmsUtils.payload(item, 'DisqusURL') || 'who-stole-my-ip.disqus.com';
    const disqusURL = _disqusURL.replace("https://","").replace("http://","");
    
    useEffect(() => {

        const existingScript = document.getElementById('disqus__comment');
        if (existingScript) {
            document.body.removeChild(existingScript);
        }

        const script = document.createElement('script');
        script.type = `text/javascript`;
        script.id = 'disqus__comment';
        script.src = 'https://' + disqusURL + '/embed.js'; //Test url : https://test-o5lneyegil.disqus.com/embed.js
        //script.src = 'https://sharkcagediving-com-au.disqus.com/embed.js'; //Test url : https://test-o5lneyegil.disqus.com/embed.js
        script.setAttribute('data-timestamp', +new Date());

        document.body.appendChild(script);

        return function cleanup(){
            //abortController.abort();
            document.body.removeChild(script);
        }

        // eslint-disable-next-line
    }, []);

    if (cmsOption.isCmsEdit) {
        return (
            <div className={cssClass}
                data-cms-item-id={item.itemId}
                id={item.anchorName}>
                <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
                    <b> Disqus Comment </b>
                </pre>
            </div>
        )
    }

    return (
        <div css={style.container}>
            <div id="disqus_thread"></div>
            <noscript>Please enable JavaScript to view the <a href="https://disqus.com/?ref_noscript">comments powered by Disqus.</a></noscript>
        </div>
    )
}